<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="user._hero_image"
					class="white--text align-end"
					:src="user._hero_image.url"
					dark
					aspect-ratio="1.6"
				>
					<!-- <v-card-title class="headline">
						{{user.firstname}} {{user.lastname}}
					</v-card-title>
					<v-card-subtitle v-if="user._membershipTitle" class="white--text">
						{{user._membershipTitle}}
					</v-card-subtitle> -->
				</v-img>
				<span
					v-if="user.entity_number"
					class="barcode"
				>
					{{ user.entity_number}}
				</span>
				<v-card-text v-if="user._details && user._details.length">
					<FilterableList
						:items="user._details"
						subtitleSrc="label"
						titleSrc="value"
						:enableClick="(false)"
						:enableSearch="(false)"
					/>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'membershipCard',
	computed: {
		...mapState([
			'user',
			'benefits',
		]),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
