<template>
	<v-form
		v-if="processedSchema && Object.keys(processedSchema).length"
		v-model="valid"
		enctype="multipart/form-data"
		ref="form"
	>
		<v-row v-if="processedSchema.fields && processedSchema.fields.length">
			<!-- Loop fields as columns -->
			<v-col
				v-for="(field, index) in processedSchema.fields"
				:key="index"
				v-bind="field.colProps"
				v-show="field.visible && !field.hidden"
			>
				<!-- Slot for before -->
				<slot name="before" v-bind:field="field" />

				<!-- Insert field depending on type -->
				<h3 v-if="field.type == 'subtitle' && field.props && field.props.label">
					{{field.props.label}}
				</h3>
				<v-text-field
					v-else-if="field.type == 'text'"
					v-bind="field.props"
					v-model="value[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					dense
					@input="$emit('change')"
				/>
				<v-textarea
					v-else-if="field.type == 'textarea'"
					v-bind="field.props"
					v-model="value[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					auto-grow
					outlined
					dense
					@input="$emit('change')"
				/>
				<v-checkbox
					v-else-if="field.type == 'checkbox'"
					v-bind="field.props"
					v-model="value[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					dense
					@change="$emit('change')"
				/>
				<template v-else-if="field.type == 'checkboxes' && field.props.items && field.props.items.length">
					<h3 v-if="field.props && field.props.label" class="mt-6 mb-3">
						{{field.props.label}}
					</h3>
					<v-checkbox
						v-for="option in field.props.items"
						:key="option.id"
						:label="option.title"
						v-model="value[field.name]"
						:value="option.name"
						:disabled="disabled || field.props.disabled"
						:rules="field.parsedRules"
						class="mt-0"
						:class="field.class"
						dense
						@change="$emit('change')"
					/>
				</template>
				<v-switch
					v-else-if="field.type == 'switch'"
					v-bind="field.props"
					v-model="value[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					dense
					@change="$emit('change')"
				/>
				<v-select
					v-else-if="field.type == 'select'"
					v-bind="field.props"
					v-model="value[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					dense
					@change="$emit('change')"
				/>
				<v-autocomplete
					v-else-if="field.type == 'autocomplete'"
					v-bind="field.props"
					v-model="value[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					dense
					@change="$emit('change')"
				/>
				<InputfieldFile
					v-else-if="field.type == 'file'"
					v-bind="field.props"
					v-model="value[field.name]"
					:name="field.name"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:items="value[field.name + '_items']"
					:class="field.class"
					@deleteItemsChange="updateDeleteFiles"
				/>
				<v-file-input
					v-else-if="field.type == 'image'"
					v-bind="field.props"
					v-model="value[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					dense
				/>
				<v-dialog
					v-else-if="field.type == 'date'"
					:ref="('dialog_' + field.name)"
					v-model="field.modalOpen"
					:return-value.sync="value[field.name]"
					persistent
					width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							v-bind="field.props"
							v-model="field.dateFormatted"
							:disabled="disabled || field.props.disabled"
							:rules="field.parsedRules"
							:class="field.class"
							readonly
							v-on="on"
							@input="$emit('change')"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="value[field.name]"
						scrollable
						v-bind="field.pickerProps"
						locale="fi"
					>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="field.modalOpen = false">Peruuta</v-btn>
						<v-btn text color="primary" @click="saveDialog('dialog_' + field.name, value[field.name])">OK</v-btn>
					</v-date-picker>
				</v-dialog>
				<div
					v-else-if="field.type == 'buttonGroup' && field.items && field.items.length"
					class="mb-4"
				>
					<p
						v-if="field.props.label"
						class="subtitle-1 mb-2"
					>
						{{field.props.label}}
						<span v-if="field.rules && field.rules.required">*</span>
					</p>
					<v-btn-toggle
						v-model="value[field.name]"
						v-bind="field.props"
					>
						<v-btn
							v-for="(item, index) in field.items"
							:key="index"
							:value="item.value || undefined"
							:disabled="disabled || field.props.disabled"
							v-bind="item.props"
							@click="$emit('change')"
						>
							<v-icon
								v-if="item.icon"
								:left="(item.title && item.title.length) ? true : false"
							>
								{{item.icon}}
							</v-icon>
							{{item.title}}
						</v-btn>
					</v-btn-toggle>
					<p
						v-if="field.props.hint"
						class="mt-2 mb-0"
					>
						{{field.props.hint}}
					</p>
				</div>
				<p v-else>
					<strong>Unknown field type: {{field.type}}</strong>
				</p>

				<!-- Slot for after -->
				<slot name="after" v-bind:field="field" />
			</v-col>
		</v-row>

		<!-- Default slot for submit buttons etc -->
		<!-- <slot /> -->

		<!-- Debug -->
		<template v-if="debug">
			<p>
				<strong>Form is valid: </strong>{{valid}}
			</p>
			<p v-if="value">
				<strong>Value:</strong><br />
				{{value}}
			</p>
			<p v-if="processedSchema">
				<strong>Processed schema:</strong><br />
				{{processedSchema}}
			</p>
			<p v-if="schema">
				<strong>Original schema:</strong><br />
				{{processedSchema}}
			</p>
		</template>
	</v-form>
</template>

<script>

import validationRules from '@/utils/validationRules'
import InputfieldFile from '@/components/InputfieldFile'

export default {
	name: 'SchemaToForm',
	components: {
		InputfieldFile,
	},
	props: {
		// Debug mode
		debug: {
			type: Boolean,
			default: () => {
				return false
			}
		},

		// User defined form schema
		schema: {
			type: Object,
			default: () => {
				return {}
			}
		},

		// Object for field values. Intended to use with v-model.
		value: {
			type: Object,
			default: () => {
				return {
					content: {}
				}
			}
		},

		// Enable field rules?
		enableRules: {
			type: Boolean,
			default: () => {
				return true
			}
		},

		// Disable all form fields?
		disabled: {
			type: Boolean,
			default: () => {
				return false
			}
		},
	},
	data: () => ({
		processedSchema: {}, // Processed schema, which we use to render the form
		valid: false, // Is form valid?
		ready: false,
		test: '',
	}),
	methods: {
		// Debug logging
		log (text = '') {
			if (!this.debug || !text) return

			console.log('SchemaToForm: ' + text)
		},

		// Debug warning logging
		logWarn (text = '') {
			if (!this.debug || !text) return

			console.warn('SchemaToForm: ' + text)
		},

		// Debug Error logging
		logError (text = '') {
			if (!this.debug || !text) return

			console.error('SchemaToForm: ' + text)
		},

		// Parse field validation rules. Actual rules are loaded from validationRules module.
		parseRules (keys = []) {
			if (!this.enableRules) return []
			if (!keys || !keys.length) return []

			return keys.map(key => {
				return validationRules[key] || undefined
			})
		},

		// Parse field class
		parseClass (field) {
			if (!field) return [];

			return [
				field.rules && (field.rules.includes('required') || field.rules.includes('requiredAllowFalse')) ? 'v-input--required' : null,
			]
		},

		parseCondition (condition = '') {
			if (!condition.length) return true

			const segments = condition.split('=')
			const conditionField = segments[0]
			const conditionValue = segments[1]

			if (['true', '1'].includes(conditionValue)) {
				if (this.debug) this.log('Checking that ' + conditionField + ' is true. Value is ' + this.value[conditionField])

				return this.value[conditionField] == true
			} else if (['false', '0'].includes(conditionValue)) {
				if (this.debug) this.log('Checking that ' + conditionField + ' is false. Value is ' + this.value[conditionField])

				return this.value[conditionField] == false
			} else if (conditionValue === 'null') {
				if (this.debug) this.log('Checking that ' + conditionField + ' is null. Value is ' + this.value[conditionField])

				return (this.value[conditionField] && this.value[conditionField].length)
			} else {
				return false
			}
		},

		saveDialog (dialog, val) {
			this.$refs[dialog][0].save(val)
		},

		formatDate (val) {
			if (!val) return null

			return new Date(val).toLocaleDateString('fi-FI')
		},

		updateDeleteFiles (e) {
			this.$set(this.value, e.field.name + '_delete', e.items)
		}
	},
	watch: {
		/*
			 User defined schema may lack some of required properties,
			 so we use this watcher to make sure that schema meets requirements.
		*/
		schema: {
			deep: true,
			immediate: true,
			handler (val) {
				try {
					this.ready = false

					// Copy user defined schema
					let newSchema = JSON.parse(JSON.stringify(val))

					// Field operations
					if (newSchema.fields && newSchema.fields.length) {
						for (let [index, field] of newSchema.fields.entries()) {
							// Check that field has name defined
							if (!field.type) throw 'Field with index ' + index + ' has no type defined.'

							// Check that field has name defined
							if (!field.name) throw 'Field with index ' + index + ' has no name defined.'

							// Merge default colprops with user defined colprops
							field.colProps = Object.assign({
								cols: 12,
							}, field.colProps)

							// Make sure that rules array is set
							field.rules = field.rules || []

							// Parse field class
							field.class = this.parseClass(field)

							// By default field is visible
							field.visible = true
						}
					} else {
						newSchema.fields = []
					}

					// Set processed schema
					this.processedSchema = newSchema

					// Filter value to contain only the fields in the schema
					this.$nextTick(() => {
						// Get allowed keys
						const allowedKeys = newSchema.fields.reduce((acc, key) => {
							if (key.type != 'subtitle') acc.push(key.name)

							return acc;
						}, []);

						// Filter value
						const filteredValue = Object.keys(this.value).filter(key => {
							return allowedKeys.includes(key)
						}).reduce((acc, key) => {
							acc[key] = this.value[key]

							return acc
						}, {})

						// Emit updated value
						this.$emit('input', filteredValue)
					})
				} catch (error) {
					this.processedSchema = {}
					this.logError('SchemaToForm: ' + error)
				} finally {
					this.$nextTick(() => {
						this.$refs.form.resetValidation()
						this.ready = true
					})
				}
			},
		},

		// Emit form field value changes
		value: {
			deep: true,
			handler (val) {
				for (let field of this.processedSchema.fields) {
					// Format date fields
					if (field.type == 'date') {
						this.$set(field, 'dateFormatted', this.formatDate(this.value[field.name]))
					}

					// If field has conditional visibility defined
					if (field.showIf) {
						field.visible = this.parseCondition(field.showIf)
					} else {
						// By default field is visible
						field.visible = true
					}

					// If field has conditional requirement defined
					if (field.requiredIf) {
						if (this.parseCondition(field.requiredIf) === true) {
							// Add required rule, if it's not already included
							if (!field.rules.includes('required')) {
								field.rules.push('required')
							}
						} else {
							// Remove included rule
							field.rules.splice(field.rules.findIndex(item => item == 'required'), 1)
						}
					}

					// Parse field rules and class
					field.parsedRules = this.parseRules(field.rules)
					field.class = this.parseClass(field)
				}

				this.$emit('input', val)
			}
		},

		// Emit form validity
		valid (val) {
			return (val) ? this.$emit('valid') : this.$emit('invalid')
		}
	},
}
</script>