export default {
	general: {
		save: 'Save',
		cancel: 'Cancel',
		yes: 'Yes',
		no: 'No',
		close: 'Close',
	},
	routes: {
		error404: {
			title: 'Page not found',
		},
		membershipCard: {
			title: 'Card',
		},
		myAccount: {
			title: 'My account',
			headline: 'My account',
		},
		benefitContainer: {
			title: 'Benefits',
			headline: 'Benefits',
		},
		benefit: {
			title: 'Benefit',
			headline: 'Benefit',
		},
		eventContainer: {
			title: 'Events',
			headline: 'Events',
		},
		event: {
			title: 'Event',
			headline: 'Event',
		},
		newsContainer: {
			title: 'News',
			headline: 'News',
		},
		news: {
			title: 'News article',
			headline: 'News article',
		},
		pageContainer: {
			title: 'Pages',
			headline: 'Pages',
		},
		page: {
			title: 'Page',
			headline: 'Page',
		},
		settings: {
			title: 'Settings',
			headline: 'Settings',
		},
	},
}