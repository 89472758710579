<i18n>
{
  "en": {
		"terms": {
			"linkText": "Read more about disclosures of data"
		}
	},
  "fi": {
		"terms": {
			"linkText": "Lue lisää tietojen luovutuksesta"
		}
	}
}
</i18n>

<template>
	<v-container class="container--narrow">
		<v-card v-if="config.forms && config.forms.myAccount">
			<v-card-text>
				<SchemaToForm
					v-model="formData"
					:schema="config.forms.myAccount"
					:disabled="saveLoading"
					:debug="(false)"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				>
					<template v-slot:after="slotProps">
						<v-btn
							v-if="slotProps.field.name === 'c_allow_international'"
							class="ml-8"
							x-small
							color="primary"
							outlined
							elevation="0"
							@click="termsDialogOpen = true"
						>
							{{$i18n.t('terms.linkText')}}
						</v-btn>
					</template>
				</SchemaToForm>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="success"
					:loading="saveLoading"
					:disabled="saveLoading || !formValid"
					@click="save"
					text
				>
					{{$i18n.t('general.save')}}
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-dialog
			v-model="termsDialogOpen"
			max-width="600"
		>
			<v-card>
				<v-card-text
					v-html="config.terms"
					class="copy"
				/>
				<v-card-actions class="justify-center">
					<v-spacer />
					<v-btn
						color="success"
						text
						@click="termsDialogOpen = false"
					>
						{{$i18n.t('general.close')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MyAccount',
	data: () => ({
		formData: {},
		saveLoading: false,
		formValid: false,
		termsDialogOpen: false,
	}),
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
	methods: {
		save () {
			this.saveLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				// Since the register may reformat values (i.e. telephone number),
				// replace form data with server response.
				if (res.body.item) {
					this.formData = res.body.item
				}

				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
	mounted () {
		this.formData = JSON.parse(JSON.stringify(this.user))
	},
};
</script>
