export default {
	general: {
		save: 'Tallenna',
		cancel: 'Peruuta',
		yes: 'Kyllä',
		no: 'Ei',
		close: 'Sulje',
	},
	routes: {
		error404: {
			title: 'Sivua ei löytynyt',
		},
		membershipCard: {
			title: 'Kortti',
		},
		myAccount: {
			title: 'Omat tiedot',
			headline: 'Omat tiedot',
		},
		benefitContainer: {
			title: 'Jäsenedut',
			headline: 'Jäsenedut',
		},
		benefit: {
			title: 'Jäsenetu',
			headline: 'Jäsenetu',
		},
		eventContainer: {
			title: 'Tapahtumat',
			headline: 'Tapahtumat',
		},
		event: {
			title: 'Tapahtuma',
			headline: 'Tapahtuma',
		},
		newsContainer: {
			title: 'Uutiset',
			headline: 'Uutiset',
		},
		news: {
			title: 'Uutinen',
			headline: 'Uutinen',
		},
		pageContainer: {
			title: 'Sivut',
			headline: 'Sivut',
		},
		page: {
			title: 'Sivu',
			headline: 'Sivu',
		},
		settings: {
			title: 'Asetukset',
			headline: 'Asetukset',
		},
	},
}