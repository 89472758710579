<i18n>
{
  "en": {
		"noItemsMessage": "No pages to show."
	},
  "fi": {
		"noItemsMessage": "Sivuja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'pageContainer'">
			<v-container
				class="container--narrow"
			>
				<FilterableList
					v-if="items.length"
					:items="items"
					@itemClick="itemClick"
				/>
				<v-alert
					v-else
					type="info"
				>
					{{$i18n.t('noItemsMessage')}}
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'PageContainer',
	computed: {
		...mapState({
			items: state => state.pages,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'page', params: { pagename: item.name } })
		}
	},
	mounted () {
		this.$api.Pages.doRequest()
	},
};
</script>
