<template>
  <div>
		<v-file-input
			v-bind="[$attrs, $listeners]"
			:disabled="disabled"
			:rules="rules"
			v-model="file"
			dense
			:multiple="multiple"
			@change="onInput"
		/>
		<v-list
			v-if="items && items.length"
			flat
		>
			<template
				v-for="(item, index) in items"
			>
				<v-list-item
					:key="item.filename"
					:class="[
						itemIsDeleted(item) ? 'v-list-item--deleted': null,
					]"
				>
					<v-list-item-content>
						<v-list-item-title v-html="item['filename']" />
					</v-list-item-content>
					<v-list-item-action>
						<v-btn
							icon
							@click="deleteItem(item)"
						>
							<v-icon
								v-if="itemIsDeleted(item)"
							>
								mdi-delete-restore
							</v-icon>
							<v-icon
								v-else
							>
								mdi-delete
							</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-divider
					v-if="index != items.length - 1" :key="('divider-' + item.filename)"
				/>
			</template>
		</v-list>
  </div>
</template>

<script>

export default {
	name: 'InputfieldFile',
	inheritAttrs: false,
  data: () => ({
		file: null,
		deleteItems: [],
	}),
	props: {
		name: {
			type: String,
			required: true,
		},
		value: {
			default: () => {
				return ''
			}
		},
		disabled: {
			type: Boolean,
			default: () => {
				return false
			}
		},
		rules: {
			type: Array,
			default: () => {
				return []
			}
		},
		items: {
			default: () => {
				return []
			}
		},
		multiple: {
			type: Boolean,
			default: () => {
				return false
			}
		}
  },
  methods: {
		onInput () {
			this.$emit('input', this.file)
		},
		itemIsDeleted (item) {
			return this.deleteItems.findIndex(file => file == item.filename) >= 0
		},
		deleteItem (item) {
			if (this.itemIsDeleted(item)) {
				let index = this.deleteItems.findIndex(file => file == item.filename)
				this.deleteItems.splice(index, 1)
			} else {
				this.deleteItems.push(item.filename)
			}
		}
	},
	watch: {
		deleteItems: {
			deep: true,
			handler (val) {
				this.$emit('deleteItemsChange', {
					field: this.$props,
					items: val,
				})
			}
		}
	},
}
</script>

<style lang="scss">
	.v-list-item--deleted {
		.v-list-item__title {
			color: maroon;
			font-weight: bold;
			text-decoration: line-through;
		}
	}
</style>